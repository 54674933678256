/********************Google Analytics********************/
var googletag = googletag || {};
  googletag.cmd = googletag.cmd || [];
  (function() {
    var gads = document.createElement("script");
    gads.async = true;
    gads.type = "text/javascript";
    var useSSL = "https:" == document.location.protocol;
    gads.src = (useSSL ? "https:" : "http:") + "//www.googletagservices.com/tag/js/gpt.js";
    var node =document.getElementsByTagName("script")[0];
    node.parentNode.insertBefore(gads, node);
  })();
  
  /********************Tealium********************/
// ******* Removing Tealium Implementation ***********
//  var utag_data = {};
//
//      (function(a,b,c,d){
//    	  window.utag_cfg_ovrd = window.utag_cfg_ovrd || {};
//    	  window.utag_cfg_ovrd.noview = true;
//      a='//tags.tiqcdn.com/utag/active/reserve-america-react/prod/utag.js';
//      b=document;c='script';d=b.createElement(c);d.src=a;d.type='text/java'+c;d.async=true;
//      a=b.getElementsByTagName(c)[0];a.parentNode.insertBefore(d,a);
//      })();
  
      
  /********************AA Offers********************/

function getOffset(el) {
  var _x = 0;
  var _y = 0;
  while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
    _x += el.offsetLeft - el.scrollLeft;
    _y += el.offsetTop - el.scrollTop;
    el = el.offsetParent;
  }
  return {top: _y, left: _x};
}


  window.addEventListener('message', receiveMessage, false);

  // Hack for Offers - Bad hack - do not replicate this.
  function receiveMessage(evt)
  {
	//console.log("got message: "+ JSON.stringify(evt.data));
	  var data = {}
	  try {
		 data = JSON.parse(evt.data);
	  } catch (e) {
		  
	  }

	if (evt.data.hasOwnProperty("AddonsIFrameHeight") && evt.data.AddonsIFrameHeight > 0) {
		var iframe = document.getElementById("addons-iframe");
		if (typeof iframe !== "undefined") {
			var offset = 150
			var parentHeight = window.innerHeight
				|| document.documentElement.clientHeight
				|| document.body.clientHeight
			var maxHeight = parentHeight - offset
			var height = evt.data.AddonsIFrameHeight
			if (height > maxHeight) {
				height = maxHeight
			}
			iframe.style.height = height + "px"; 
		}
    }

	//IFrame height
	if (evt.data.hasOwnProperty("FacilityIFrameHeight") && evt.data.FacilityIFrameHeight > 0) {
		var iframe = document.getElementById("facility-iframe");
		if (typeof iframe !== "undefined") {
	        iframe.style.height = evt.data.FacilityIFrameHeight + "px"; 
		}
    }
	if (evt.data.hasOwnProperty("FacilityIFrameScrollTop")) {
		var iframe = document.getElementById("facility-iframe");
		if (typeof iframe !== "undefined") {
	        const facilityIFrameY = getOffset(iframe).top;
			window.scrollTo(0, facilityIFrameY);
		}
    }
	  // check if offer
	  // AAOffer iframe resize
	  if (typeof data.offerType !== "undefined") {
		  if (typeof data.height !== "undefined" && typeof data.width !== "undefined") {
			  var ht = data.height + "px";
			  var wt = data.width + "px";
			  var element = document.getElementById("offerFrame");
			  if (typeof element !== "undefined") {
				  element.style.height = ht;
			  }
			  var element = document.getElementById("offerDiv");
			  if (typeof element !== "undefined") {
				  element.style.maxWidth = wt;
			  }
			  
			  var element = document.getElementById("iframeWrapper");
			  if (typeof element !== "undefined") {
				  element.style.maxWidth = (data.width + 2) + "px";
			  }			  
		  }
	  }
  }

